var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "block" },
      [
        _c("el-button", { on: { click: _vm.moveSessions } }, [
          _vm._v("Move sessions"),
        ]),
        _vm._m(0),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "block_options checkbox" },
      [
        _c(
          "el-checkbox",
          {
            model: {
              value: _vm.move_sessions.move_all,
              callback: function ($$v) {
                _vm.$set(_vm.move_sessions, "move_all", $$v)
              },
              expression: "move_sessions.move_all",
            },
          },
          [_vm._v(" Move all sessions in selected component ")]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "block_options" },
      [
        _c(
          "el-select",
          {
            attrs: {
              filterable: "",
              multiple: "",
              placeholder: "Filter by users",
              "value-key": "id",
              size: "mini",
              disabled: _vm.move_sessions.move_all,
            },
            model: {
              value: _vm.move_sessions.users,
              callback: function ($$v) {
                _vm.$set(_vm.move_sessions, "users", $$v)
              },
              expression: "move_sessions.users",
            },
          },
          _vm._l(_vm.users, function (u) {
            return _c("el-option", {
              key: u.id,
              attrs: { label: u.name, value: u },
            })
          }),
          1
        ),
        _c("el-input", {
          attrs: {
            size: "mini",
            placeholder: "Filter by note",
            disabled: _vm.move_sessions.move_all,
          },
          model: {
            value: _vm.move_sessions.note,
            callback: function ($$v) {
              _vm.$set(_vm.move_sessions, "note", $$v)
            },
            expression: "move_sessions.note",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "block_options" },
      [
        _c("el-date-picker", {
          attrs: {
            type: "date",
            size: "mini",
            clearable: false,
            format: "dd/MM/yyyy",
            disabled: _vm.move_sessions.move_all,
          },
          model: {
            value: _vm.move_sessions.from_date,
            callback: function ($$v) {
              _vm.$set(_vm.move_sessions, "from_date", $$v)
            },
            expression: "move_sessions.from_date",
          },
        }),
        _c("el-date-picker", {
          attrs: {
            type: "date",
            size: "mini",
            clearable: false,
            format: "dd/MM/yyyy",
            disabled: _vm.move_sessions.move_all,
          },
          model: {
            value: _vm.move_sessions.to_date,
            callback: function ($$v) {
              _vm.$set(_vm.move_sessions, "to_date", $$v)
            },
            expression: "move_sessions.to_date",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "block_options" },
      [
        _c("project-picker", {
          attrs: {
            projects: _vm.projects,
            project: _vm.move_sessions.from_project,
            placeholder: "From Project",
          },
          on: { change: _vm.changedFromProject },
        }),
        _c("i", {
          staticClass: "el-icon-right",
          staticStyle: { width: "20px" },
        }),
        _c("project-picker", {
          attrs: {
            projects: _vm.projects,
            project: _vm.move_sessions.to_project,
            placeholder: "To Project",
          },
          on: { change: _vm.changedToProject },
        }),
      ],
      1
    ),
    _vm.move_sessions.from_project
      ? _c(
          "div",
          { staticClass: "block_options" },
          [
            _c("component-picker", {
              attrs: {
                project_id: _vm.move_sessions.from_project.id,
                component: _vm.move_sessions.from_component,
                placeholder: "From Component",
              },
              on: {
                change: (val) => {
                  _vm.move_sessions.from_component = val
                },
              },
            }),
            _c("i", {
              staticClass: "el-icon-right",
              staticStyle: { width: "20px" },
            }),
            _c("component-picker", {
              attrs: {
                project_id: _vm.move_sessions.to_project.id,
                component: _vm.move_sessions.to_component,
                placeholder: "To Component",
              },
              on: {
                change: (val) => {
                  _vm.move_sessions.to_component = val
                },
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("span", [_vm._v("Date filters are needed")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }