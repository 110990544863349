var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "block" },
      [
        _c("el-button", { on: { click: _vm.findSessions } }, [
          _vm._v("Find Short Sessions"),
        ]),
        _vm.sessions.length
          ? _c("div", [
              _c("span", [
                _vm._v(
                  "Found " + _vm._s(_vm.sessions.length) + " sessions matching"
                ),
              ]),
            ])
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "block_options" },
      [
        _c("el-input", {
          attrs: { size: "mini", placeholder: "Max Session Length in seconds" },
          model: {
            value: _vm.max_time,
            callback: function ($$v) {
              _vm.max_time = $$v
            },
            expression: "max_time",
          },
        }),
        _c("el-input", {
          attrs: { size: "mini", placeholder: "User Id" },
          model: {
            value: _vm.user_id,
            callback: function ($$v) {
              _vm.user_id = $$v
            },
            expression: "user_id",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "block_options" },
      [
        _c("el-date-picker", {
          attrs: {
            type: "date",
            size: "mini",
            clearable: false,
            format: "dd/MM/yyyy",
          },
          model: {
            value: _vm.from_date,
            callback: function ($$v) {
              _vm.from_date = $$v
            },
            expression: "from_date",
          },
        }),
        _c("el-date-picker", {
          attrs: {
            type: "date",
            size: "mini",
            clearable: false,
            format: "dd/MM/yyyy",
          },
          model: {
            value: _vm.to_date,
            callback: function ($$v) {
              _vm.to_date = $$v
            },
            expression: "to_date",
          },
        }),
      ],
      1
    ),
    _vm.sessions.length
      ? _c(
          "div",
          { staticClass: "block" },
          [
            _c("el-button", { on: { click: _vm.deleteSessions } }, [
              _vm._v("Delete Sessions?"),
            ]),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }