var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("span", [_vm._v("What would you like to do?")]),
      _c("move-sessions"),
      _c("hr"),
      _c("move-component"),
      _c("hr"),
      _c("delete-component"),
      _c("delete-stage"),
      _c("hr"),
      _c("convert-completed-stage-components"),
      _c("hr"),
      _c("find-orphaned-sessions"),
      _c("find-orphaned-invoices"),
      _c("find-orphaned-line-items"),
      _c("hr"),
      _c("find-short-sessions"),
      _c("hr"),
      _c("sessions-dump"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }