var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "block" },
      [
        _c("el-button", { on: { click: _vm.find } }, [
          _vm._v(" Find orphaned sessions "),
        ]),
        _vm.orphans.sessions.length
          ? _c(
              "el-table",
              { attrs: { data: _vm.orphans.sessions, size: "mini" } },
              [
                _c("el-table-column", { attrs: { prop: "id", label: "ID" } }),
                _c("el-table-column", {
                  attrs: { prop: "start", label: "Start" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "stop", label: "Stop" },
                }),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }