var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "block" },
      [
        _c("el-button", { on: { click: _vm.deleteComponent } }, [
          _vm._v(" Delete component "),
        ]),
        _vm._m(0),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "block_options" },
      [
        _c("el-input", {
          attrs: { size: "mini", placeholder: "Component" },
          model: {
            value: _vm.deletion.component,
            callback: function ($$v) {
              _vm.$set(_vm.deletion, "component", $$v)
            },
            expression: "deletion.component",
          },
        }),
      ],
      1
    ),
    _vm.messages.delete.component.length > 0
      ? _c(
          "div",
          { staticClass: "dialog" },
          [
            _vm.messages.delete.component.length > 0
              ? _vm._l(_vm.messages.delete.component, function (m, idx) {
                  return _c("div", { key: idx, staticClass: "error" }, [
                    _c("span", [_vm._v(" " + _vm._s(m) + " ")]),
                  ])
                })
              : _vm._e(),
            _c(
              "div",
              [
                _c(
                  "el-button-group",
                  [
                    _vm.promises.deletion.component.resolve
                      ? [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "danger", size: "mini" },
                              on: {
                                click: _vm.promises.deletion.component.resolve,
                              },
                            },
                            [_vm._v(" Delete Anyway ")]
                          ),
                        ]
                      : _vm._e(),
                    _vm.promises.deletion.component.reject
                      ? [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini" },
                              on: {
                                click: _vm.promises.deletion.component.reject,
                              },
                            },
                            [_vm._v(" Cancel ")]
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("span", [_vm._v("Double check component before running this!")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }