var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "block" },
    [
      _c("el-button", { on: { click: _vm.convert } }, [
        _vm._v("Convert incomplete"),
      ]),
      _c("div", [
        _c("span", [
          _vm._v(
            " " +
              _vm._s(_vm.incomplete_components.length) +
              " active components in completed stages "
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }