var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "block" },
      [
        _c("el-button", { on: { click: _vm.find } }, [
          _vm._v(" Find orphaned line items "),
        ]),
        _vm.orphans.line_items.length && !_vm.searching
          ? _c(
              "div",
              { staticClass: "row" },
              [
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.orphans.line_items.length) +
                      " line " +
                      _vm._s(
                        _vm._f("pluralize")(
                          "item",
                          _vm.orphans.line_items.length
                        )
                      ) +
                      " found "
                  ),
                ]),
                _c(
                  "el-button",
                  {
                    attrs: { size: "mini", type: "danger" },
                    on: { click: _vm.remove },
                  },
                  [_vm._v(" Delete ")]
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }