var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-select",
    {
      attrs: {
        filterable: "",
        value: _vm.project,
        placeholder: _vm.placeholder,
        "value-key": "id",
        size: "mini",
      },
      on: { change: (val) => _vm.$emit("change", val) },
    },
    _vm._l(_vm.sorted_projects, function (p) {
      return _c(
        "el-option",
        { key: p.id, attrs: { label: p.name, value: p } },
        [
          _c("span", { staticStyle: { float: "left" } }, [
            _vm._v(" " + _vm._s(p.name) + " "),
          ]),
          _c(
            "span",
            {
              staticStyle: {
                float: "right",
                color: "#8492a6",
                "font-size": "13px",
              },
            },
            [_vm._v(" " + _vm._s(p.ref) + " ")]
          ),
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }