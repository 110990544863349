var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dump" }, [
    _c(
      "div",
      { staticClass: "block" },
      [
        _c("el-button", { on: { click: _vm.dumpSessions } }, [
          _vm._v("Dump sessions"),
        ]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "block_options" },
      [
        _c("project-picker", {
          attrs: { projects: _vm.projects, project: _vm.dump_sessions.project },
          on: { change: _vm.changedProject },
        }),
      ],
      1
    ),
    _vm.dump_sessions.project
      ? _c(
          "div",
          { staticClass: "block_options" },
          [
            _c("stage-picker", {
              attrs: {
                project_id: _vm.dump_sessions.project.id,
                stage: _vm.dump_sessions.stage,
              },
              on: {
                change: (val) => {
                  _vm.dump_sessions.stage = val
                },
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm.dump_sessions.stage
      ? _c(
          "div",
          { staticClass: "block_options" },
          [
            _c("el-date-picker", {
              attrs: {
                type: "date",
                size: "mini",
                clearable: false,
                format: "dd/MM/yyyy",
              },
              model: {
                value: _vm.dump_sessions.from_date,
                callback: function ($$v) {
                  _vm.$set(_vm.dump_sessions, "from_date", $$v)
                },
                expression: "dump_sessions.from_date",
              },
            }),
            _c("el-date-picker", {
              attrs: {
                type: "date",
                size: "mini",
                clearable: false,
                format: "dd/MM/yyyy",
              },
              model: {
                value: _vm.dump_sessions.to_date,
                callback: function ($$v) {
                  _vm.$set(_vm.dump_sessions, "to_date", $$v)
                },
                expression: "dump_sessions.to_date",
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm.dump
      ? _c(
          "div",
          {
            staticClass: "block_dump",
            attrs: { id: "block_dump" },
            on: { click: _vm.copyContentsToClipboard },
          },
          _vm._l(Object.keys(_vm.dump), function (day) {
            return _c("span", { key: day }, [
              _c("b", [_vm._v(_vm._s(day))]),
              _c(
                "ul",
                _vm._l(_vm.dump[day], function (session, idx) {
                  return _c("li", { key: `${idx}_${day}` }, [
                    _c("span", { domProps: { innerHTML: _vm._s(session) } }),
                  ])
                }),
                0
              ),
            ])
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }