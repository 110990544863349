var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "block" },
      [
        _c("el-button", { on: { click: _vm.moveComponent } }, [
          _vm._v("Move component"),
        ]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "block_options" },
      [
        _c("el-input", {
          attrs: { size: "mini", placeholder: "Component" },
          model: {
            value: _vm.move_component.component,
            callback: function ($$v) {
              _vm.$set(_vm.move_component, "component", $$v)
            },
            expression: "move_component.component",
          },
        }),
        _c("el-input", {
          attrs: { size: "mini", placeholder: "To Stage" },
          model: {
            value: _vm.move_component.to_stage,
            callback: function ($$v) {
              _vm.$set(_vm.move_component, "to_stage", $$v)
            },
            expression: "move_component.to_stage",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }