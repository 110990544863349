var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-select",
    {
      attrs: {
        filterable: "",
        value: _vm.stage,
        placeholder: "Select stage",
        "value-key": "id",
        size: "mini",
      },
      on: { change: (val) => _vm.$emit("change", val) },
    },
    _vm._l(_vm.stages, function (s) {
      return _c(
        "el-option",
        { key: s.id, attrs: { label: `Stage #${s.number}`, value: s } },
        [
          _c(
            "span",
            { staticStyle: { "font-size": "13px", "margin-right": "30px" } },
            [_vm._v(" Stage #" + _vm._s(s.number) + " ")]
          ),
          s.notes
            ? _c(
                "span",
                {
                  staticStyle: {
                    color: "#8492a6",
                    "font-size": "11px",
                    float: "right",
                  },
                },
                [_vm._v(" " + _vm._s(s.notes) + " ")]
              )
            : _vm._e(),
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }